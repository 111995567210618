import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import useLocalStorageToggle from '../hooks/useLocalStorageToggle';

import LeadStatus from "./Helpers/LeadStatus"
import LeadState from "./Helpers/LeadState"
import PrioritiseLead from "./Helpers/PrioritiseLead"
import AssignLead from "./Helpers/AssignLead"
import LeadSubjects from "./Helpers/LeadSubjects"
import Loader from "./Loader"
import EventScheduler from "/engines/communication/app/javascript/communication/EventScheduler"
import EventList from "/engines/communication/app/javascript/communication/EventList"
import EditLeadModal from "./EditModal"
import RejectLeadModal from "./RejectModal"
import ActList from "../acts/List"
import NewActModal from "../acts/NewModal"
import Select from 'react-select';

import { fetchActs, fetchEvents, unhandleLead, sendQualificationFlowLead, fetchLead, updateLead, fetchProducts } from "./api"

import { pascalCaseToSnakeCase } from "../custom/helpers";

const editorContentStyle = `
table {
  width: 100% !important;
}
p, h1, h2, h3, h4, h5 {
  margin: 0px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
p {
  font-size: 14px;
}
`

const editorInit = {
  height: 450,
  menubar: false,
  object_resizing: false,
  contextmenu: false,
  content_style: editorContentStyle,
  branding: false,
  resize: true,
  statusbar: true,
  elementpath: false,
  toolbar: "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | image",
  paste_as_text: true,
  paste_data_images: true,
  plugins: ["table", "paste", "lists"],
}

const Sidebar = ({ lead, setLead, setLeads }) => {
  const [acts, setActs] = useState([])
  const [actsLoading, setActsLoading] = useState(false)

  const [events, setEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(false)

  const [newActType, setNewActType] = useState("")

  const [newLeadNote, setNewLeadNote] = useState(null);
  const [newLeadNoteChanged, setNewLeadNoteChanged] = useState(false);

  const [assignedProducts, setAssignedProducts] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [updatingProducts, setUpdatingProducts] = useState(false)

  const sidebarRef = useRef(null);
  const editLeadRef = useRef(null);
  const rejectLeadRef = useRef(null);
  const newActRef = useRef(null);
  const noteEditorRef = useRef(null);

  // Handler for LeadConvertedToCaseFile event
  const handleLeadConvertedToCaseFile = (event) => {
    const convertedLead = event?.detail?.lead;

    if (convertedLead) {
      // update lead
      setLead(convertedLead);

      updateLeadInLeads(convertedLead);
    }
  };

  // Function to close sidebar on click outside
  const handleClickOutside = (event) => {
    // If a modal is open, don't close sidebar on click outside
    if (document.querySelector(".modal-backdrop")) return;

    if (lead && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setLead(null);
    }
  };

  // Add event listener for LeadConvertedToCaseFile event dispatched in leads/convert_to_case_file.js.erb
  useEffect(() => {
    window.addEventListener("LeadConvertedToCaseFile", handleLeadConvertedToCaseFile);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("LeadConvertedToCaseFile", handleLeadConvertedToCaseFile);
    };
  }, []); // The empty dependency array ensures that the effect runs only once on mount

  // Fetch all products
  useEffect(() => {
    fetchProducts()
      .then(response => response.json())
      .then(data => setAllProducts(data.map((product) => {
        return {
          value: product.id,
          label: product.title
        }
      })) ?? [])
      .catch(err => toastr.error(err.message));
  }, [])

  // Add event listener on mount to close sidebar on click outside
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    // reset newLeadNote
    setNewLeadNote(null);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [lead]);

  // Set the assigned products when the lead changes
  useEffect(() => {
    if (!lead?.products) return setAssignedProducts([])

    setAssignedProducts(lead.products.map((product) => {
      return {
        value: product.id,
        label: product.title
      }
    }));
  }, [lead && lead.id]);

  // Fetch acts on lead change
  useEffect(() => {
    setActsLoading(true)

    if (!lead) {
      setActs([])
      setActsLoading(false)
    } else {
      fetchActs({ leadId: lead.id })
        .then((response) => response.json())
        .then((data) => {
          setActs(data)
          setActsLoading(false)
        })
    }
  }, [lead && lead.id])

  // Fetch events on lead change
  useEffect(() => {
    setEventsLoading(true)

    if (!lead) {
      setEvents([]);
      setEventsLoading(false)
    } else {
      fetchEvents({ leadId: lead.id })
        .then((response) => response.json())
        .then((data) => {
          setEvents(data)
          setEventsLoading(false)
        })
    }
  }, [lead && lead.id])

  // Set the note editor content when the lead changes
  useEffect(() => {
    setNewLeadNote(lead?.note?.body ?? null);
  }, [lead && lead.id]);

  // Effect to handle updating the lead note
  useEffect(() => {
    let timeoutId;

    // Function to make the PATCH request
    const makePatchRequest = () => {
      if(newLeadNote === null){
        return; // Don't do api call to save if there is no active note
      }

      updateLead({
        leadId: lead.id,
        atrs: {
          note_attributes: {
            id: lead.note.id,
            body: newLeadNote,
          }
        }
      })
        .then((response) => response.json())
        .then((data) => {
          // Update lead state unless sidebar was closed
          setLead( currentLead => currentLead?.id === data.id ? data : currentLead );
          updateLeadInLeads(data);
        });
    };

    // Set a timeout to make the PATCH request after 2 seconds of inactivity
    if (newLeadNoteChanged) {
      timeoutId = setTimeout(() => {
        makePatchRequest();
        setNewLeadNoteChanged(false);
      }, 2000);
    }

    // Cleanup function to clear the timeout if the component unmounts or text changes
    return () => clearTimeout(timeoutId);

  }, [newLeadNote, newLeadNoteChanged]);

  const handleActCreated = (act) => {
    // Close modal
    newActRef.current.click();

    // prepend act to acts
    setActs((acts) => [act, ...acts]);

    // update lead
    fetchAndUpdateLead();
  }

  const handleLeadUpdated = (lead) => {
    // Close modal
    editLeadRef.current.click();

    // Update lead state
    setLead(lead);
    updateLeadInLeads(lead);
  }

  const handleLeadRejected = (lead) => {
    // Close modal
    rejectLeadRef.current.click();

    // Update lead state
    setLead(lead);
    updateLeadInLeads(lead);
  }

  const handleLeadUnhandled = () => {
    unhandleLead({ leadId: lead.id })
      .then((response) => response.json())
      .then((data) => {
        // Update lead state
        setLead(data);
        updateLeadInLeads(data);
      });
  }

  const handleLeadSendQualificationFlow = () => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm("Are you sure you want to send the qualification flow?");

    if (isConfirmed) {
      // If the user confirms, proceed with the operation
      sendQualificationFlowLead({ leadId: lead.id })
        .then((response) => response.json())
        .then((data) => {
          // Update lead state
          setLead(data);
          updateLeadInLeads(data);
        })
        .catch((error) => {
          console.error('Error sending qualification flow lead:', error);
        });
    } else {
      // If the user cancels, do nothing
      console.log("Operation canceled by the user.");
    }
  };

  // Sometimes the lead is indirectly updated (e.g. when an act or event is created).
  // This function fetches the lead and updates the state.
  const fetchAndUpdateLead = () => {
    return fetchLead({ leadId: lead.id })
      .then((response) => response.json())
      .then((data) => {
        // Update lead state
        setLead(data);
        updateLeadInLeads(data);
      });
  }

  const updateLeadProducts = (selectedProducts) => {
    setUpdatingProducts(true);
    var productIds = selectedProducts?.map(product => product.value) ?? [];

    updateLead({
      leadId: lead.id,
      atrs: {
        product_connections_external_ids: productIds
      }
    })
      .then((response) => response.json())
      .then((data) => {
        // Update the assigned products of the current lead
        var products = !data.products ? [] : data.products.map(product => {
          return {
            value: product.id,
            label: product.title
          }
        });
        setAssignedProducts(products);
        updateLeadInLeads(data);
        setUpdatingProducts(false);
      });
  }

  // Only update the lead that was updated
  const updateLeadInLeads = (lead) => {
    setLeads((leads) => {
      return leads.map((_lead) => {
        if (_lead.id === lead.id) {
          return lead;
        } else {
          return _lead;
        }
      });
    });
  }

  const openNewActModal = (type) => {
    setNewActType(type);
    newActRef.current.click();
  }

  const [sidebarExpanded, toggleSidebarExpanded] = useLocalStorageToggle('leads-sidebar-expanded', false)

  return (
    <>
      <div className={`right-sidebar-container ${sidebarExpanded ? 'right-sidebar-container-xl' : ''} vh-100-navbar-offset${lead ? " active" : ""}`}>
        <div className="right-sidebar-overlay"></div>
        <div
          className="right-sidebar"
          ref={sidebarRef}
        >
          {lead && (
            <>
              <div className={`${sidebarExpanded ? 'container-xl' : 'container'} px-3 px-sm-4 py-sm-4`}>
                <div className="sidebar-grid">
                  <div className="sidebar-area-customer">
                    <div id="sidebar-customer-info">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <LeadStatus
                            status={lead.status}
                            className="mr-2" />

                          <LeadState
                            state={lead.workflow_state}
                            className="mr-2" />

                          <PrioritiseLead
                            lead={lead}
                            setLead={setLead}
                            setLeads={setLeads}
                            className="mr-2" />

                          <i
                            className="ph ph-link-simple ph-lg cursor-pointer"
                            onClick={() => navigator.clipboard.writeText(window.location.href)} />
                        </div>

                        <div className="d-flex align-items-center">
                          <a
                            href="#"
                            className="mr-2"
                            data-toggle="modal"
                            data-target="#edit-lead-modal"
                            ref={editLeadRef}
                          >
                            {window.I18n.t("shared.edit")}
                          </a>

                          <i
                            className="ph ph-x ph-thin ph-xl cursor-pointer"
                            onClick={() => setLead(null)} />
                        </div>
                      </div>

                      <h5 className="my-3">{lead.customer_info?.name}</h5>

                      <div className="d-flex align-items-center flex-wrap mb-3">
                        <div className="d-flex align-items-center mr-3">
                          <i className="ph ph-phone ph-thin mr-1" />
                          <a href={`tel:${lead.customer_info.phone}`}>
                            {lead.customer_info.phone}
                          </a>
                        </div>

                        <div className="d-flex align-items-center mr-3">
                          <i className="ph ph-envelope ph-thin mr-1" />
                          <a href={`mailto:${lead.customer_info.email}`}>
                            {lead.customer_info.email}
                          </a>
                        </div>

                        { lead.user &&
                          <div className="d-flex align-items-center">
                            <i className="ph ph-user ph-thin mr-1" />
                            <a href={`/users/${lead.user.id}`}>
                              {I18n.t("leads.sidebar.go_to_user")}
                            </a>
                          </div>
                        }
                      </div>

                      <AssignLead
                        lead={lead}
                        setLead={setLead}
                        setLeads={setLeads} />

                      {lead.lead_subjects.length > 0 && (
                        <>
                          <hr className="my-4" />

                          <LeadSubjects leadSubjects={lead.lead_subjects} />
                        </>
                      )}
                    </div>

                    <hr className="my-4" />

                    <div id="sidebar-lead-info">
                      <h6 className="d-block mb-2">
                        {I18n.t("leads.sidebar.lead_information")}
                      </h6>

                      <div className="d-flex justify-content-between flex-wrap">
                        <div className="d-flex flex-column justify-content-between mr-3 mb-3">
                          <small className="mb-2">{I18n.t("activerecord.attributes.lead.source")}</small>
                          <small className="text-muted">{lead.source || "-"}</small>
                        </div>

                        <div className="d-flex flex-column justify-content-between mr-3 mb-3">
                          <small className="mb-2">{I18n.t("activerecord.attributes.lead.mediator")}</small>
                          <small className="text-muted">
                            {lead.mediator_name || lead.mediator_phone || lead.mediator_email ? (
                              <>
                                {lead.mediator_name}
                                {lead.mediator_phone && (
                                  <>
                                    <br />
                                    <a href={`tel:${lead.mediator_phone}`}>
                                      {lead.mediator_phone}
                                    </a>
                                  </>
                                )}
                                {lead.mediator_email && (
                                  <>
                                    <br />
                                    <a title={lead.mediator_email} href={`mailto:${lead.mediator_email}`}>
                                      {lead.mediator_email.length <= 30 ? (
                                        lead.mediator_email
                                      ) : (
                                        <>{lead.mediator_email.substring(0, 30)}...</>
                                      )}
                                    </a>
                                  </>
                                )}
                              </>
                            ) : (
                              <>{I18n.t("shared.none")}</>
                            )}
                          </small>
                        </div>
                        <div className="d-flex flex-column justify-content-between mr-3 mb-3">
                          <small className="mb-2">{I18n.t("activerecord.attributes.lead.channel")}</small>
                          <small className="text-muted">
                            {lead.channel && (
                              <>
                                <br />
                                {lead.channel}
                              </>
                            )}
                          </small>
                        </div>

                        <div className="d-flex flex-column justify-content-between mr-3 mb-3">
                          <small className="mb-2">{I18n.t("activerecord.attributes.lead.kind")}</small>
                          <small className="text-muted">{lead.kind}</small>
                        </div>
                      </div>

                      <div className="d-flex flex-column justify-content-between align-items-start mb-3">
                        <small className="mb-2">{I18n.t("activerecord.attributes.lead.segment_id")}</small>
                        {lead.segment ? (
                          <>
                            <img
                              src={lead.segment?.logo_url}
                              alt={lead.segment?.name}
                              className="mb-1"
                              style={{ maxHeight: "30px", maxWidth: "200px", objectFit: "contain" }} />
                            <small className="text-muted">{lead.segment.partner.name} - {lead.segment.name}</small>
                            {lead.segment.senior && (
                              <div className="d-flex align-items-center">
                                <i className="ph ph-info ph-lg text-danger mr-1" />
                                <small className="text-muted">Senior lawyers only</small>
                              </div>
                            )}
                          </>
                        ) : (
                          <small className="text-muted">{I18n.t("shared.none")}</small>
                        )}
                      </div>

                      <div className="d-flex flex-column justify-content-between mb-3">
                        <small className="mb-2">{I18n.t("activerecord.attributes.lead.category_id")}</small>
                        <small className="text-muted">{lead.category?.title || I18n.t("shared.none")}</small>
                      </div>

                      <div className="d-flex flex-column justify-content-between mb-3">
                        <small className="mb-2">{I18n.t("activerecord.attributes.lead.channel")}</small>
                        <small className="text-muted">{lead.channel || I18n.t("shared.none")}</small>
                      </div>

                      <div className="d-flex flex-column justify-content-between mb-3">
                        <small className="mb-2">{I18n.t("activerecord.attributes.lead.description")}</small>
                        <small className="text-muted">{lead.description || I18n.t("shared.none")}</small>
                      </div>

                      <div className="d-flex flex-column justify-content-between">
                        <small className="mb-2">{I18n.t("activerecord.attributes.lead.created_by")}</small>
                        <small className="text-muted">{lead.created_by?.name || I18n.t("shared.none")}</small>
                      </div>

                      <div className="sidebar-area-products mt-3">
                        <div className="form-group">
                          <div className="d-flex align-items-center mb-2">
                            <label className="mb-0 mr-1" htmlFor="lead_product_ids">{I18n.t("activerecord.attributes.lead.products")}</label>
                            {updatingProducts ? <i className="spin spin-xxxs" /> : null}
                          </div>
                          <Select
                            placeholder={I18n.t("activerecord.attributes.lead.products")}
                            closeMenuOnSelect={false}
                            value={assignedProducts}
                            isMulti
                            options={allProducts}
                            onChange={updateLeadProducts} />
                        </div>
                      </div>
                    </div>
                    {window.currentTenant.organization_tenant.settings.enable_calendar && (
                      <>
                        <hr className="my-4" />

                        <div id="sidebar-scheduler">
                          <div
                            className="mb-4 position-relative"
                            style={{ minHeight: "30px" }}
                          >
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <small className="d-block">
                                {I18n.t("leads.sidebar.events")}
                              </small>

                              {lead.permissions.can_create_event && (
                                <a
                                  href={`/communication/calendar?toggle_new_event_modal=true&lead_id=${lead.id}`}
                                  className="small"
                                >
                                  {I18n.t("communication.calendar_app.new_event")}
                                </a>
                              )}
                            </div>

                            {eventsLoading ? (
                              <Loader
                                height="30px"
                                width="30px" />
                            ) : (
                              <EventList events={events} />
                            )}
                          </div>

                          <small className="d-block mb-2">
                            {I18n.t("leads.sidebar.schedule_event")}
                          </small>

                          {lead.permissions.can_create_event ? (
                            <EventScheduler
                              leadId={lead.id}
                              participants={[lead.customer_info.email]}
                              onlySeniorLawyers={lead.segment?.senior || false}
                              callback={fetchAndUpdateLead} />
                          ) : (
                            <small className="d-block text-muted">
                              {I18n.t("leads.sidebar.no_permission_to_schedule_event")}
                            </small>
                          )}
                        </div>
                      </>
                    )}

                    <hr className="my-4" />
                  </div>

                  <div className="sidebar-area-notes">
                    <div id="sidebar-notes">
                      <div className="form-group">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="d-flex align-items-center">
                            <label className="mb-0 mr-1" htmlFor="note">
                              {I18n.t("activerecord.attributes.lead.note")}
                            </label>
                            {newLeadNoteChanged ? <i className="spin spin-xxxs" /> : null}
                          </div>
                          <i
                            role="button"
                            onClick={toggleSidebarExpanded}
                            className={`ph ${sidebarExpanded ? 'ph-arrows-in' : 'ph-arrows-out'} ph-lg`}
                          />
                        </div>
                        <Editor
                          onInit={(evt, editor) => noteEditorRef.current = editor}
                          onEditorChange={(content, editor) => {
                            setNewLeadNote(content);
                            setNewLeadNoteChanged(true);
                          }}
                          value={newLeadNote}
                          init={editorInit}
                        />
                      </div>
                    </div>

                    {!sidebarExpanded && (<hr className="my-4" />) }
                  </div>

                  <div className="sidebar-area-acts">
                    <div id="sidebar-actions">
                      <small className="d-block mb-2">
                        {I18n.t("leads.sidebar.log_an_action")}
                      </small>

                      <div className="row row-spaced-1">
                        {["Act::Email", "Act::Sms", "Act::IncomingCall", "Act::OutgoingCall"].map((type) => (
                          <div
                            className="col-6"
                            key={type}
                          >
                            <button
                              type="button"
                              className="btn btn-outline-primary btn-sm text-sm rounded-pill btn-block"
                              onClick={() => openNewActModal(type)}
                            >
                              {window.I18n.t(`activerecord.attributes.act.types.${pascalCaseToSnakeCase(type.replace("Act::", ""))}`)}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="mt-3">
                      {actsLoading ? (
                        <Loader height="30px" width="30px" />
                      ) : (
                        <ActList acts={acts} setActs={setActs} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer">
                <div className="container px-3 px-sm-4 py-2 py-sm-3">
                  <div className="row row-spaced-2 justify-content-between align-items-center flex-wrap">
                    <div className="col-4">
                      {lead.permissions.can_create_case_file && (
                        <button
                          type="button"
                          className="btn btn-primary-dark btn-sm btn-block rounded-pill"
                          data-toggle="modal"
                          data-target="#new-case-modal"
                          data-lead-id={lead.id}
                          data-title={lead.title}
                          data-description={lead.description}
                          data-customer-info={JSON.stringify(lead.customer_info)}
                          data-submit-url={`/leads/${lead.id}/convert_to_case_file`}
                        >
                          {I18n.t("leads.sidebar.create_case")}
                        </button>
                      )}
                    </div>

                    <div className="col-4">
                      {lead.permissions.can_unhandle && (
                        <button
                          type="button"
                          className="btn btn-info btn-sm btn-block rounded-pill"
                          onClick={handleLeadUnhandled}
                        >
                          {I18n.t("leads.sidebar.unhandle")}
                        </button>
                      )}
                    </div>

                    <div className="col-4">
                      {lead.permissions.can_send_qualification_flow && (
                        <button
                          type="button"
                          className="btn btn-info btn-sm btn-block rounded-pill"
                          onClick={handleLeadSendQualificationFlow}
                        >
                          {I18n.t("leads.sidebar.send_qualification_flow")}
                        </button>
                      )}
                    </div>

                    <div className="col-4">
                      {lead.permissions.can_reject && (
                        <button
                          type="button"
                          className="btn btn-danger btn-sm btn-block rounded-pill"
                          data-toggle="modal"
                          data-target="#reject-lead-modal"
                          ref={rejectLeadRef}
                        >
                          {I18n.t("leads.sidebar.reject")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

        </div>
      </div>

      <button
        type="button"
        className="d-none"
        data-toggle="modal"
        data-target="#leads-sidebar-new-act-modal"
        ref={newActRef} />

      <NewActModal
        modalId="leads-sidebar-new-act-modal"
        type={newActType}
        subjectType="Lead"
        subjectId={lead?.id}
        callback={handleActCreated} />

      <EditLeadModal
        lead={lead}
        callback={handleLeadUpdated} />

      <RejectLeadModal
        lead={lead}
        callback={handleLeadRejected} />
    </>
  )
}

export default Sidebar
