window.currentAuth = function () {
  return JSON.parse(window.getCookieFromBrowser('user_info') || '{}');
}

window.refreshJwt = function () {
  const url = '/jwts/refresh'

  return fetch(url, {
    method: 'POST',
  })
}

window.getCookieFromBrowser = function(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      let cookieValue = c.substring(name.length, c.length);
      return decodeURIComponent(cookieValue); // Decode each cookie value individually
    }
  }
  return "";
}

document.addEventListener('DOMContentLoaded', () => {
  let lastActivityTime = moment();

  // Update the last activity time on user interaction
  const updateLastActivityTime = () => {
    lastActivityTime = moment();
  };

  // Add event listeners for various user interactions
  document.addEventListener('click', updateLastActivityTime);
  document.addEventListener('scroll', updateLastActivityTime);
  document.addEventListener('keypress', updateLastActivityTime);

  setInterval(() => {
    if (currentAuth()) {
      // Check if there was activity in the last 15 minutes
      const now = moment();
      const timeSinceLastActivity = now.diff(lastActivityTime, 'minutes');

      if (timeSinceLastActivity <= 15) {
        // Get the expiration datetime for the JWT
        let expirationDateTime = moment(currentAuth().expires);

        // Calculate the difference in seconds
        let diffInSeconds = expirationDateTime.diff(now, 'seconds');

        // Check if the expiration time is within 5 minutes from now
        if (diffInSeconds >= 0 && diffInSeconds <= 5 * 60) {
          refreshJwt();
        }
      }
    }
  }, 60 * 1000); // check every minute
});
